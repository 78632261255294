<template>
    <b-tabs v-model="activeTab" content-class="mt-3">
        <b-tab title="Grunddata">
            <b-form @submit.prevent="onSubmit">
                <b-form-group id="input-group-name" label="Namn" label-for="input-name">
                    <b-form-input id="input-name"
                                  v-model="form.name"
                                  type="text"
                                  placeholder="Ange namn"
                                  :disabled="isSubmitting"
                                  autocomplete="off"
                                  aria-describedby="input-name-help-block"
                                  required />
                    <b-form-text id="input-name-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Name')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

                <b-form-group id="input-group-controlNext"
                              label="Nästa kontroll"
                              label-for="input-controlNext">
                    <b-input-group>
                        <b-form-datepicker id="input-controlNext"
                                           v-model="form.controlNext"
                                           type="date"
                                           placeholder="Ange nästa kontroll"
                                           :disabled="isSubmitting"
                                           autocomplete="off"
                                           aria-describedby="input-controlNext-help-block"
                                           :locale="'sv-SE'"
                                           label-help="Du kan använda piltangenterna för att navigera"
                                           :start-weekday="1" />

                        <b-input-group-append>
                            <b-button size="sm"
                                      variant="secondary"
                                      @click="form.controlNext = null">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-form-text id="input-nextControl-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('ControlNext')"
                                :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

                <b-form-group id="input-group-user"
                              label="Ansvarig"
                              label-for="input-user">
                    <BuildingScheduleResponsibleSelectList v-model="form.userId"
                                                           :user-full-name="form.userFullName"
                                                           :disabled="isSubmitting"
                                                           :building-id="buildingId" />
                    <b-form-text id="input-name-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('UserId')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

                <!--Recurrent-->
                <label for="input-controlRecurrentIntervall">Återkommande</label>
                <b-input-group prepend="Intervall">
                    <b-form-input id="input-controlRecurrentIntervall"
                                  v-model="form.controlRecurrentIntervall"
                                  min="0"
                                  max="9999"
                                  type="number"
                                  placeholder="Ange intervall"
                                  :disabled="isSubmitting"
                                  autocomplete="off"
                                  required />
                    <b-form-select id="input-controlRecurrentType"
                                   v-model="form.controlRecurrentType"
                                   :disabled="isSubmitting"
                                   :options="form.controlRecurrentTypes"
                                   required />
                </b-input-group>
                <b-form-text id="input-controlRecurrentIntervall-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('ControlRecurrentIntervall')"
                            :key="message">
                            {{ message }}
                        </li>
                        <li v-for="message in validationByKey('ControlRecurrentType')"
                            :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
                <!-- ./ Recurrent-->

                <b-row class="m-0 mt-2">
                    <b-col md="4" sm="12">
                        <b-form-group id="input-group-active"
                                      label="Aktiv"
                                      label-for="input-active">
                            <b-form-checkbox id="input-active"
                                             v-model="form.active"
                                             :disabled="isSubmitting"
                                             aria-describedby="input-active-help-block">
                            </b-form-checkbox>
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('Active')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-group>
                    </b-col>

                    <b-col md="4" sm="12">
                        <b-form-group id="input-group-reminder"
                                      label="Påminnelse"
                                      label-for="input-reminder">
                            <b-form-checkbox id="input-reminder"
                                             v-model="form.reminder"
                                             :disabled="isSubmitting"
                                             aria-describedby="input-reminder-help-block">
                            </b-form-checkbox>
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('Reminder')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-group>
                    </b-col>

                    <b-col md="4" sm="12">
                        <b-form-group id="input-group-isService"
                                      label="Service"
                                      label-for="input-isService">
                            <b-form-checkbox id="input-isService"
                                             v-model="form.isService"
                                             :disabled="isSubmitting"
                                             aria-describedby="input-isService-help-block">
                            </b-form-checkbox>
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('IsService')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-form-group id="input-group-reminder-mail-list"
                              label="Mailpåminnelser"
                              label-for="input-reminder-mail-list"
                              v-show="form.reminder">
                    <b-form-textarea id="input-reminder-mail-list"
                                     v-model="form.reminderMailList"
                                     :disabled="isSubmitting"
                                     placeholder="Ange emailadresser som ska få påminnelse. Separera med semikolon."
                                     rows="3"
                                     aria-describedby="input-reminder-mail-list-help-block">
                    </b-form-textarea>
                    <ul class="text-danger mb-0" v-if="validationByKey('ReminderMailList').length > 0">
                        <li style="list-style-type: none;margin-left: -20px;">Följande e-postadress(er) är fel</li>
                        <li v-for="message in validationByKey('ReminderMailList')"
                            :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-group>

                <b-alert :show="validationByKey('Other').length > 0" variant="danger">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Other')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-alert>

                <b-alert :show="successMessage.length > 0"
                         variant="success"
                         class="mb-0 mt-4">
                    <h6 class="mb-0 text-center">
                        <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                    </h6>
                </b-alert>

                <b-button v-b-tooltip.hover
                          variant="primary"
                          type="submit"
                          block
                          class="mt-4"
                          :disabled="isSubmitting"
                          :title="isSubmitting ? 'Sparar...' : ''">
                    <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
                </b-button>
            </b-form>
        </b-tab>
        <b-tab title="Positioner" :disabled="form.id === 0" lazy>
            <SaveBuildingSchedulePositions :building-id="buildingId"
                                           :building-schedule-id="form.id" />
        </b-tab>
    </b-tabs>
</template>

<script>
    import { get, post } from '@/helpers/api';
    import { validateEmail } from '@/helpers/email.helper';
    import BuildingScheduleResponsibleSelectList from '@/components/building/BuildingScheduleResponsibleSelectList';
    import SaveBuildingSchedulePositions from '@/components/building/SaveBuildingSchedulePositions';

    export default {
        name: 'SaveBuildingSchedule',
        components: {
            BuildingScheduleResponsibleSelectList,
            SaveBuildingSchedulePositions
        },
        props: {
            id: Number,
            buildingId: Number
        },
        watch: {
            form: {
                deep: true,
                handler(newValue, oldValue) {
                    if (!newValue.reminder) {
                        this.form.reminderMailList = null;
                        if (this.validationErrors !== null && this.validationErrors.hasOwnProperty('ReminderMailList')) this.$delete(this.validationErrors, 'ReminderMailList');
                    }
                }
            }
        },
        data() {
            return {
                activeTab: 0,
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                form: {
                    id: null,
                    buildingId: this.buildingId,
                    name: '',
                    controlNext: null,
                    controlRecurrentType: '',
                    controlRecurrentIntervall: '',
                    active: false,
                    reminder: false,
                    isService: false,
                    reminderMailList: null
                }
            };
        },
        computed: {
            isEditMode() {
                return this.form.id !== null;
            }
        },
        async mounted() {
            this.form.id = this.id !== 0 ? this.id : null;

            await get('BuildingSchedule', `Get/${this.isEditMode ? this.form.id : 0}`)
                .then((x) => {
                    this.form = x.data;
                    this.form.buildingId = this.buildingId;
                    this.form.controlNext = this.$options.filters.datetime(
                        this.form.controlNext,
                        'yyyy-MM-dd'
                    );
                })
                .catch(() => {
                    this.$router.push({
                        name: 'error',
                        params: {
                            statusCode: 400,
                            statusText: 'Något gick snett.'
                        }
                    });
                });
        },
        methods: {
            isRecipientEmailsValid() {
                // Remove last ';'.
                if (this.form.reminderMailList.endsWith(';'))
                    this.form.reminderMailList = this.form.reminderMailList.substring(0, this.form.reminderMailList.length - 1);

                // Split.
                const emails = this.form.reminderMailList.split(';');
                let invalidEmails = [];

                for (var i = 0; i < emails.length; i++) {
                    if (!validateEmail(emails[i].trim()))
                        invalidEmails.push(emails[i].trim());
                }

                this.validationErrors = { ReminderMailList: invalidEmails };
                return !(invalidEmails.length > 0);
            },
            isFormValid() {
                return this.form.reminder ? this.isRecipientEmailsValid() : true;
            },
            async onSubmit() {
                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;

                if (!this.isFormValid()) {
                    this.isSubmitting = false;
                    return;
                }

                // Post.
                await post('BuildingSchedule', `Save/${this.form.buildingId}`, this.form)
                    .then((x) => {
                        this.form.id = x.data.entityId;
                        this.successMessage = `Sparade ${this.form.name}`;
                        this.validationErrors = x.data.errors;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                    });
                this.isSubmitting = false;

                // On created.
                if (!this.validationErrors && this.form.id) this.onCreated();
            },
            onCreated() {
                this.hasSavedSuccessfullly = true;
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.form = {
                    id: null,
                    buildingId: this.buildingId,
                    name: '',
                    controlNext: null,
                    controlRecurrentType: '',
                    controlRecurrentIntervall: '',
                    active: false,
                    reminder: false,
                    isService: false,
                    reminderMailList: null
                };
                this.validationErrors = null;
            }
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>