<template>
    <div>
        <b-form-select :id="id"
                       v-model="settings.selectedPageSize"
                       :options="settings.options"
                       @change="onChange" />
    </div>
</template>

<script>
    import {
        getByKey as getSetting,
        save as saveSetting
    } from '@/services/setting.service';
    import {
        getByKey as getFiltersForView,
        save as setFiltersForView
    } from '@/services/view-filter.service';

    export default {
        name: 'PaginationPageSizeSelect',
        props: {
            id: {
                type: String,
                required: true
            },
            value: Number,
            options: {
                type: Array,
                required: false
            },
            view: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                settings: {
                    selectedPageSize: null,
                    options: []
                }
            };
        },
        mounted() {
            const settings = getSetting('pagination');

            // Sets selectedPageSize to first option if current value is not available.
            if (this.options) {
                settings.options = this.options;
                if (settings.options.indexOf(settings.selectedPageSize) === -1)
                    settings.selectedPageSize = this.options[0];
            } else {
                if (settings.options.indexOf(settings.selectedPageSize) === -1)
                    settings.selectedPageSize = settings.options[0];
            }

            // Set to scope and emit v-model change.
            this.settings = settings;

            if (this.view) {
                const viewFilters = getFiltersForView(this.view);
                if (viewFilters && viewFilters.perPage)
                    this.settings.selectedPageSize = viewFilters.perPage;
            }
            this.$emit('input', this.settings.selectedPageSize);
        },
        methods: {
            onChange(newValue) {
                saveSetting('pagination', this.settings).then(() =>
                    this.$emit('input', newValue)
                );
            }
        }
    };
</script>
